import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, CustomButton, SecondaryButton } from '../../../Core/FormInput';
import { getDiagnosticSheetHeaderDetails } from '../../../Core/Service/diagnosticSheets';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';

import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { getHandOverDetails, saveHandOverSheets } from '../../../Core/Service/handOverSheet-service';
import moment from 'moment/moment';
import { AppStorage } from '../../../Core/Service/storage-service';

const getStripedStyle = (index) => {
    return { background: index % 2 ? 'rgb(151 133 133 / 6%)' : 'rgb(158 158 158 / 20%)' };
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.hover
        //     color: theme.palette.common.white
    },
    body: {
        fontSize: 14,
        padding: '5px',
        verticalAlign: 'top'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#ECEFF1'
        },
        '&:nth-of-type(odd)': {
            backgroundColor: '#f5f2c5'
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white'
        },
        '& .MuiButton-root.Mui-disabled': {
            color: 'grey',
            fontWeight: 700
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 900
    }
});

const HandOverSheetScreen = (props) => {
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);
    const classes = useStyles();

    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const headerId = props?.match?.params?.jobId;

    const [state, setState] = useState({ showAdd: true });

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    useEffect(async () => {
        let headerRes = await getDiagnosticSheetHeaderDetails(headerId);

        setState((st) => ({ ...st, headerRecords: headerRes?.data?.headerDetails[0] || {} }));
    }, [headerId]);

    useEffect(async () => {
        let res = await getHandOverDetails(headerId);
        if (res.success) {
            const updatedUser = res?.data;
            setInputList(
                !updatedUser.handoverSheetAddVM.length
                    ? [{ ...data }]
                    : updatedUser.handoverSheetAddVM.map((c, i) => ({
                          ...c,
                          id: i + 1,
                          handoverSheetRepairRequired: c.repairRequired,
                          handoverSheetAuthorised: c.authorised,
                          handoverSheetActionComment: c.actionComment,
                          handoverSheetActioned: c.actioned,
                          isDisabled: !AppStorage.getCanAmendToDoList() && (c.actioned ? true : false),
                          isRemovedBtn: c.authorised ? true : false
                      }))
            );
        }
    }, []);

    let data = {
        handoverSheetID: null,
        handoverSheetRepairRequired: '',
        handoverSheetAuthorised: null,
        handoverSheetActionComment: '',
        handoverSheetActioned: null
    };
    const [inputList, setInputList] = useState([{ ...data }]);
    const [btnLoader, setBtnLoader] = useState(false);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index]['id'] = index + 1;
        list[index][name] = value;
        setInputList(list);
    };
    const handleCheckbox = (event, index) => {
        const { name, checked } = event.target;
        const list = [...inputList];
        list[index]['id'] = index + 1;
        list[index][name] = checked;
        if (name == 'handoverSheetAuthorised' && checked == false) {
            list[index].handoverSheetActioned = false;
        }
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { ...data }]);
    };
    // console.log(inputList, 'inputList.length - 1 === i ? true : false');
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleValidation = () => {
        let formIsValid = true;
        const list = [...inputList].map((q) => {
            if (!q.handoverSheetRepairRequired) {
                q.errorHandoverSheetRepairRequired = 'Please Enter Repair Required';
                formIsValid = false;
            } else {
                q.errorHandoverSheetRepairRequired = '';
            }
            return { ...q };
        });
        setInputList(list);
        return formIsValid;
    };

    const buttonClick = (name, value, index) => {
        const list = [...inputList];
        list[index]['id'] = index + 1;
        if (name === 'handoverSheetAuthorised') {
            if (value == list[index][name]) {
                list[index][name] = null;
                list[index].handoverSheetActioned = null;
            } else {
                list[index][name] = value;
                list[index].handoverSheetActioned = null;
            }
        } else {
            if (value == list[index][name]) {
                list[index][name] = null;
            } else {
                list[index][name] = value;
            }
        }
        setInputList(list);
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            setBtnLoader(true);
            const obj = {
                handoverSheetAddVM: inputList.map((q) => {
                    return {
                        handoverSheetID: q.handoverSheetID || null,
                        handoverSheetRepairRequired: q.handoverSheetRepairRequired,
                        handoverSheetAuthorised: q.handoverSheetAuthorised,
                        handoverSheetActionComment: q.handoverSheetActionComment,
                        handoverSheetActioned: q.handoverSheetActioned
                    };
                }),
                handoverSheetSignature: null
            };
            let res = await saveHandOverSheets(headerId, obj);
            if (res.success) {
                showToast(res.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            } else {
                showToast(res.error);
            }
        }
        setBtnLoader(false);
    };

    console.log(AppStorage.getCanActionedHandoverSheet(), 'AppStorage.getCanActionedHandoverSheet()');
    return (
        <div style={{ padding: '10px', textAlign: 'left', backgroundColor: 'white' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={7} sm={7} lg={10} style={{ fontSize: 21, textAlign: 'left' }}>
                            Reg No:&nbsp; <b> {state.headerRecords?.registration}</b>&nbsp; WIP No:&nbsp; <b> {state.headerRecords?.wipNumber}</b>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">
                                        <CustomButton icon={AddBox} onClick={handleAddClick} toolTipTitle="Add Row" />
                                    </StyledTableCell>
                                    <StyledTableCell width="55%" style={{ color: 'red' }}>
                                        Repair Required
                                    </StyledTableCell>
                                    <StyledTableCell width="7%" align="center">
                                        Authorised
                                    </StyledTableCell>
                                    <StyledTableCell width="7%" align="center">
                                        Actioned
                                    </StyledTableCell>
                                    <StyledTableCell width="30%">Comments</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inputList.map((x, i) => {
                                    return (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell align="center" style={{ verticalAlign: 'middle' }}>
                                                {inputList.length !== 1 && state.showAdd && !x.isRemovedBtn && (
                                                    <CustomButton
                                                        IconColor="#3f51b5"
                                                        icon={RemoveCircle}
                                                        onClick={() => handleRemoveClick(i)}
                                                        toolTipTitle="Remove Row"
                                                    />
                                                )}
                                                {/* {i === 0 && state.showAdd && <CustomButton icon={AddBox} onClick={handleAddClick} toolTipTitle="Add Row" />} */}
                                            </StyledTableCell>
                                            <StyledTableCell style={{ verticalAlign: 'top' }}>
                                                {x.isDisabled ? (
                                                    <div style={{ padding: '5px 11px 8px 11px' }}>{x.handoverSheetRepairRequired}</div>
                                                ) : (
                                                    <TextBox
                                                        multiline
                                                        // rows={2}
                                                        name="handoverSheetRepairRequired"
                                                        autoFocus={true}
                                                        value={x.handoverSheetRepairRequired}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        disabled={x.isDisabled}
                                                    />
                                                )}
                                                {x.createdBy && (
                                                    <InputLabel shrink style={{ padding: '0px', fontWeight: 600 }}>{`Created By ${x.createdBy}-${moment(
                                                        x.dateCreated
                                                    ).format('LLL')}`}</InputLabel>
                                                )}
                                                {x.errorHandoverSheetRepairRequired && (
                                                    <InputLabel shrink error>
                                                        {x.errorHandoverSheetRepairRequired}
                                                    </InputLabel>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {x.authorisedBy ? (
                                                    <div style={{ display: 'inline-grid', fontWeight: 500 }}>
                                                        <span>{x.authorisedBy}</span>
                                                        <span shrink>{moment(x.dateAuthorised).format('DD-MMM-YY')}</span>
                                                    </div>
                                                ) : (
                                                    // <Checkbox
                                                    //     checked={x.handoverSheetAuthorised}
                                                    //     onChange={(e) => handleCheckbox(e, i)}
                                                    //     name="handoverSheetAuthorised"
                                                    //     disabled={x.isDisabled}
                                                    // />
                                                    <ButtonGroup
                                                        color="primary"
                                                        aria-label="outlined primary button group"
                                                        style={{ marginTop: 10 }}
                                                        disabled={!AppStorage.getCanAuthorisedHandoverSheet()}
                                                    >
                                                        <Button
                                                            onClick={() => buttonClick('handoverSheetAuthorised', 1, i)}
                                                            variant={x.handoverSheetAuthorised == 1 && 'contained'}
                                                        >
                                                            Yes
                                                        </Button>
                                                        <Button
                                                            onClick={() => buttonClick('handoverSheetAuthorised', 0, i)}
                                                            variant={x.handoverSheetAuthorised == 0 && 'contained'}
                                                        >
                                                            No
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {x.actionedBy ? (
                                                    <div style={{ display: 'inline-grid' }}>
                                                        <span>{x.actionedBy}</span>
                                                        <span shrink>{moment(x.dateActioned).format('DD-MMM-YY')}</span>
                                                    </div>
                                                ) : (
                                                    // <Checkbox
                                                    //     checked={x.handoverSheetActioned}
                                                    //     onChange={(e) => handleCheckbox(e, i)}
                                                    //     name="handoverSheetActioned"
                                                    //     // disabled={!x.handoverSheetAuthorised || x.isDisabled}
                                                    //     disabled={x.isDisabled ? x.isDisabled : !x.handoverSheetAuthorised}
                                                    // />

                                                    <ButtonGroup
                                                        color="primary"
                                                        aria-label="outlined primary button group"
                                                        style={{ marginTop: 10 }}
                                                        // disabled={!AppStorage.getCanActionedHandoverSheet()}
                                                        disabled={x.handoverSheetAuthorised != 1 || !AppStorage.getCanActionedHandoverSheet()}
                                                    >
                                                        <Button
                                                            onClick={() => buttonClick('handoverSheetActioned', 1, i)}
                                                            variant={x.handoverSheetActioned == 1 && 'contained'}
                                                        >
                                                            Yes
                                                        </Button>
                                                        <Button
                                                            onClick={() => buttonClick('handoverSheetActioned', 0, i)}
                                                            variant={x.handoverSheetActioned == 0 && 'contained'}
                                                        >
                                                            No
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell style={{ verticalAlign: 'top' }}>
                                                {x.isDisabled ? (
                                                    <div style={{ padding: '5px 11px 8px 11px' }}>{x.handoverSheetActionComment}</div>
                                                ) : (
                                                    <TextBox
                                                        multiline
                                                        rows={2}
                                                        name="handoverSheetActionComment"
                                                        value={x.handoverSheetActionComment}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        disabled={x.isDisabled}
                                                    />
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SecondaryButton isBtnLoader={btnLoader} className="Submitbtn" onClick={handleSubmit} style={{ marginTop: 8 }}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default HandOverSheetScreen;
